import React from "react"

//bu sure to add to $numberOfItems in global.css!!!!
let testimonials = [
  { user: "James W.", quote: "Great tutorials and always tons of value!" },
  {
    user: "Sheila G.",
    quote:
      "Jordan makes it easy to follow. He avoids unnecessary repeating and has content rich videos.",
  },
  {
    user: "Bernard A.",
    quote:
      "I must admit I was a bit skeptical about making money on the internet but ur videos have changed my perspective",
  },
  {
    user: "Chase J.",
    quote:
      "This content is straight to the point and easy to understand.",
  },
  {
    user: "Monica R.",
    quote:
      "I have been watching your videos like crazy, you are so amazing for sharing your ways with the world",
  },
  {
    user: "Kim M.",
    quote:
      "This is legit no games or false hoods. He lays it flat out for you.",
  },
  {
    user: "Jamie M.",
    quote:
      "You're pretty much an example of what I want my Business to look like.",
  },
  {user:"Joseph T", quote:"I have finished the Millionaire Mindset Course. It has and still is expanding my mindsets to a Millionaire mindset and unlocking the potential and dreams that reside in me."},
  {
    user: "CL C.",
    quote:
      "I just learned dropshipping from you. A whole new opportunity. Thank you.",
  },
  {
    user: "Van B.",
    quote: "Your videos are some of the best I've seen. Good stuff!",
  },
]

testimonials = [...testimonials, ...testimonials]
//double wrap for slider
export default () => {
  return (
    <div className="slider">
      <div className="slide-track">
        {testimonials.map((testimonial, i) => {
          return (
            <div className="slide-item" key={i}>
              <div className="item hover-zoom" key={i}>
                <div className="item-content">
                  <div>{testimonial.quote}</div>
                </div>
                <div className="item-name">-{testimonial.user}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
